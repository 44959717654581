@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.banner{
 border-bottom: 1px solid rgb(16, 8, 49);   
}

.c-page-head{
    margin-top: 10%;
    font-size:7rem;
    color: #0d0d32;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}
.c-page-side-heading{
    color:#ffffff;
    margin-top: 10%;
    font-weight: bold;
    text-align: center;
    font-size:7rem;
}

.c-page-right{
    background-color: #0d0d32;
}
.c-contact-page-section{
    display: flex;
    justify-content: center;
    text-align: center;
    justify-items: auto;
}
.c-page-lastimg{
    max-width: 100%;
}
.c-page-compart{
    background-color: #0d0d32;
    color: white;
    text-align: center;
    padding: 5%;
}
.c-page-list{
    text-decoration: none;
    list-style: none;
    line-height: 6vh;
   
}
.c-page-list > li {
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
}




@media only screen and (max-width: 700px) {
    .c-page-head{
        margin-top: 8%;
        font-size:5rem;
        color: #0d0d32;
        text-align: center;
        font-family: "Quicksand", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
    }
    .c-page-side-heading{
        color:#ffffff;
        margin-top: 8%;
        font-weight: bold;
        text-align: center;
        font-size:3rem;
    }
    .c-page-list > li {
        font-family: "Quicksand", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
}