.small-card {
    position: relative; /* Add this to make the positioning of the image relative to this div */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  
  .small-card-image {
    position: absolute;
    top: 10px; /* Adjust the top position as needed */
    left: 10px; /* Adjust the left position as needed */
    width: 50px; /* Adjust the width of the image as needed */
    height: 50px; /* Adjust the height of the image as needed */
    border-radius: 50%; /* Makes the image round */
    overflow:auto;
  }
  
  .founder-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire container */
  }
  
  .smallcard-para {
    padding: 10%;
  }
  
  .foundersNme {
    font-weight: 700;
  }
  