.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup.success {
  border: 2px solid #4caf50;
}

.popup.error {
  border: 2px solid #f44336;
}

.icon {
  font-size: 2em;
  margin-bottom: 10px;
}

h3 {
  margin: 10px 0;
}

p {
  margin: 0;
}
