.service-img{
    max-width: 100%;
}

.service-heading{
    text-align: center;
    margin-top: 20%;
    font-weight: 600;
    color: #2a2a90;
}
.service-para{
    padding: 10% 18%;
    justify-content: left;
    font-size: 1.2rem;
}
.jutsu{
    margin-top: 0%;
}
.service-main-para{
    padding: 5% 10%;
    font-size: 1.5rem;
}
.list{
    text-align: center;
    margin-top: 5%;
}
.list h3{
    font-size: 2rem;
    font-weight: 600;
}
.list ul{
    text-decoration: none;
    list-style-type: none;
}
.list ul li{
    line-height:80px;
}
.serv-img{
    margin-top: 19%;
}
.service-banner-image{
max-width: 100%;
}
@media screen and (max-width: 720px){

    .service-heading{
        text-align: center;
        margin-top: 5%;
        font-weight: 600;
        color: #2a2a90;
    }
    .serv-img{
        margin-top: 8%;
    }
    .service-para{
        padding: 4%;
        justify-content: left;
        font-size: .8rem;
    }
    .service-main-para{
        padding: 1.3%;
        font-size: .8rem;
    }
    .list ul li{
        line-height:40px;
    }
}