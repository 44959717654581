@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Matemasie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Playwrite+CU:wght@100..400&family=Protest+Guerrilla&family=Sevillana&display=swap');
.banner{
 border-bottom: 1px solid rgb(16, 8, 49);   
}
.c-page-head{
    margin-top: 10%;
    font-size:9rem;
    color: #0d0d32;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}
.ip-banner-image{
    max-width: 100%;
}
.letter-spacing{
    letter-spacing:40px;
}
.c-page-side-heading{
    color:#ffffff;
    margin-top: 10%;
    font-weight:600;
    text-align: center;
    font-size:7rem;
}

.influencer-typing-container{
text-align: center;
    font-size: 7rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
}
.special-carac{
font-weight: 500;
color: rgb(142, 98, 164);

}

.inf-subhead-left{
    text-align: center;
    font-size: 4rem;
    font-weight: 300;
    font-family: "Quicksand", sans-serif;
}

.inf-para-left{
font-size: 2.5rem;
font-weight: 300;
font-family: "Dancing Script", cursive;

}
.col-mar{
    margin-top: 5%;
    text-align: center;
}


.big-button{
    text-decoration: none;
    padding: 20px 40px;
    font-size: 1.8rem;
    background-color: #0d0d31;
    color: #ffffff;
    border-radius: 20px;
}


.big-button:hover{
    text-decoration: none;
    padding: 20px 40px;
    font-size: 1.8rem;
    background-color: #ffffff;
    color: #0d0d31;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease, font-size 0.3s

}

.c-sub-page-head{
    text-align: center;
    color: #0d0d32;
    font-family: "Quicksand", sans-serif;
    font-size:2rem;
}

.c-sub-page-h{
    font-size:9rem;
    color: #0d0d32;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}


.gc-main {
    /* Optional: Flexbox for center alignment */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.gc-container {
    background: linear-gradient(to bottom right, #000000, #000000); /* Gradient from light paper color to a slightly darker shade */
    padding: 20px; /* Space inside the paper */
    border-radius: 8px; /* Slightly rounded corners for a paper-like feel */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* Soft shadow for depth */
                0 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #e0e0e0; /* Subtle border to resemble paper edges */
    font-family: 'Arial', sans-serif; /* Choose a readable font */
    max-width: 100%; /* Responsive width */
    transition: transform 0.3s ease; /* Smooth hover effect */
}

/* Styling for header inside the container */
.gc-head {
    font-size: 1.8rem; /* Larger font size for emphasis */
    margin-bottom: 10px; /* Space below the heading */
    font-family: "Sevillana", cursive;
    font-weight: 900;
    font-style: normal;
    background-image: linear-gradient(to right, #ffffff, #ffffff, #ffffff); /* Gradient from coral to slate blue to light sea green */
    -webkit-background-clip: text; /* Clips the gradient to the text */
    color: transparent;
}

/* Styling for paragraph inside the container */
.gc-para {
    font-size: 1rem; /* Standard font size for paragraphs */
    color: #efefef; /* Slightly lighter text color for contrast */
    line-height: 1.6; /* Improved readability with line spacing */
}

/* Hover effect for the container */
.gc-container:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), /* Stronger shadow on hover */
                0 2px 5px rgba(0, 0, 0, 0.1);
}

.text-over-video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.813); /* Adds shadow to make text stand out */
    text-align: center;
}

.inf-uniqu-1{
    font-size: 5rem;
    font-family: "Sevillana", cursive;
    font-weight: 400;
    color: #811644;
    text-align: right;
}

.inf-uniqu-2{
    margin-top: 5%;
    font-size: 4.5rem;
    font-family: "Protest Guerrilla", sans-serif;
    font-weight: 400;
    color: #582a95; 
    text-align: center;
}

.inf-uniqu-3{
    font-size: 3rem;
    font-family: "Playwrite CU", cursive;
    font-weight: 400;
    color: #1f2d7d; 
    text-align: center;
}

.inf-uniqu-4{
    margin-top: 5%;
    font-size: 3rem;
    font-family: "Exo", sans-serif;
    font-weight: 400;
    color: #7b4569; 
    text-align: left;
}


@media only screen and (max-width: 700px) {
    .c-page-head{
        margin-top: 8%;
        font-size:4rem;
        color: #0d0d32;
        text-align: center;
        font-family: "Quicksand", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
    }
 
    .c-page-side-heading{
        color:#ffffff;
        margin-top: 8%;
        font-weight: 600;
        text-align: center;
        font-size:3rem;
    }
    .influencer-typing-container{
        text-align: center;
            font-size: 4rem;
            font-family: "Quicksand", sans-serif;
            font-weight: 200;
        }
        .special-carac{
        font-weight: 300;
        color: rgb(142, 98, 164);
        
        }
        .c-sub-page-head{
    text-align: center;
    color: #0d0d32;
    font-family: "Quicksand", sans-serif;
    font-size:1rem;
}

.c-sub-page-h{
    font-size:4rem;
    color: #0d0d32;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.inf-uniqu-1{
    font-size: 3rem;
    font-family: "Sevillana", cursive;
    font-weight: 400;
    color: #811644;
    text-align: center;
}

.inf-uniqu-2{
    margin-top: 3%;
    font-size: 3rem;
    font-family: "Protest Guerrilla", sans-serif;
    font-weight: 200;
    color: #582a95; 
    text-align: center;
}

.inf-uniqu-3{
    font-size: 1.3rem;
    font-family: "Playwrite CU", cursive;
    font-weight: 200;
    color: #1f2d7d; 
    text-align: center;
}

.inf-uniqu-4{
    margin-top: 3%;
    font-size: 2.5rem;
    font-family: "Exo", sans-serif;
    font-weight: 400;
    color: #7b4569; 
    text-align: center;
}

}