.write {
margin-top:2%;
border: none;
border-bottom: 1px solid rgb(25, 25, 25);
outline: none;
}
.write:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #fefefe; /* You can set the border style here */
  }


.contact{
    text-align: center;
    margin-top:5%;
}
label{
    margin-top: 2%;
    font-size: 1.4rem;
}
.contact-button{
    padding: 10px 20px;
    background-color: #2a2a90;
    color: white;
    border-radius: 10px;
    text-align: center;
    margin-top:5%;
    
}
.contact-image{
    max-width: 80%;
    margin-top: 8%;
}
@media only screen and (max-width: 700px) {
    label{
        margin-top: 5%;
    }

    .contact-button{
        padding: 10px 20px;
        background-color: #2a2a90;
        color: white;
        border-radius: 10px;
        text-align: center;
        margin-top:20px;
        
    }
    .contact-image{
        max-width: 80%;
    }
           }
 