.brand-navbar{
    color: #ffffff;
    font-size: 3rem;
}
.footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
    position: relative;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #0c0c3c; /* Change the link color as needed */
    font-weight: bold;
    font-size: 1.3rem; /* Adjust the font size as needed */
    transition: color 0.3s, border-bottom 0.3s;
  }
  
  /* Add a line below the link on hover */
  .footer-links a:hover {
    color: #0c0c3c; /* Change the link color on hover */
    border-bottom: 2px solid #0c0c3c; /* Add a bottom border on hover */
  }
.footer-section{color: #ffffff;

}
.footerWrapper{
    padding:5%;
    background-color: #0c0c3c;

}
.footer-right-section{
  color: rgb(255, 255, 255);
}
@media screen and (max-width: 720px){

   
}