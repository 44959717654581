
.product-card{
    padding:10%;
    margin-top: 10px;
    transition-duration: 1s;
    border-radius: 10px;
    
 
}
.product-card-img{
    max-width: 90%;
}
.product-card-descritpion{
   justify-content:left;
   box-shadow: rgba(61, 61, 86, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   border-bottom: 1px solid rgb(164, 161, 161);
}
.product-para{
    font-weight: 1px;
    text-align: center;
}
.ar{
    visibility: hidden;
}
.product-buttons{
    padding: 20px 40px;
    text-decoration: none;
    background-color: #2a2a90;
    color: white;
    border-radius: 10px;
    transition-duration:0.2s;
}
.product-buttons:hover{
    background-color: white ;
    color: black;
}
.ar:hover{
    visibility: visible;
    color:black;
}
.product-card:hover{
padding:5%;
}
.card-minitext{
    padding: 2%;
}

.new-card-services {
    padding: 10%;
    color: black; /* Default text color */
    background-color: white; /* Default background color */
    text-decoration: none; /* Remove underline */
    display: block; /* Ensure the entire link area is clickable */
  }
.new-card-services:hover {
    color: white; /* Text color on hover */
    background-color:#2a2a90; /* Background color on hover */
  }


