.shop-card{
    text-align: center;
    padding: 5%;
    border-radius: 20px;
    margin-top: 2%;
}

.shop-item-image{
    max-width: 100%;
}

.shop-explore-button{
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: black;
    color: aliceblue;
}


/* Modal Background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-width: 80%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal Visibility */
  .modal-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  
  /* Close Button */
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  

@media screen and (max-width: 720px){

    .shop-card{
        text-align: center;
        padding: 3%;
        border-radius: 20px;
        margin-top: 4%;
    }


.shop-item-image{
    max-width: 100%;
}

.shop-product-name{
    font-size: 3vh;
}
.shop-explore-button{
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: black;
    color: aliceblue;
}
}