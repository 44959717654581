@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&display=swap');

.home-banner{
    position: relative;
}
.home-banner-section{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  .s-logo{
    font-size: 4rem;
    color: #0d0d32;
  }
.banner-img{
 width: 100%;
 height:80vh;
 filter: brightness(60%);
}
.home-para{
    text-align: Left;
    padding: 5%;
    justify-content: left;
    color:white;
    max-width: 40%;
}
.home-para-paragraph{
    text-align: left;
    padding: 6%;
    justify-content: left;
    color: wheat;
    max-width: 40%;
    margin-top: -10%;
    font-size: 1.5rem;
}
.home-trust-icons{
    font-size: larger;
    color: rgb(26, 40, 99);
    text-align: center;
    align-items: center;
}
.info-button{
    text-decoration: none;
    padding: 20px 60px;
    background-color:#ffffff ;
    color: rgb(16, 22, 70) ;
    font-weight: 600;
    border-radius:0px;

}
.home-paras{
    padding:5%;
    justify-content: left;
}
.bgg{
    background: url("../../img/bg.png") no-repeat ;
}
.home-paras ul li{
    list-style: none;
    margin-top: 5px;
    font-weight: 600;
    font-size: 1.3rem;
}
.home-paras p {
    font-size: 1.5rem;
}

.home-trust-section{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 60px;
}
.home-trust-icons{
    font-size: 50px;
}

.imm{
    max-width: 90%;
}
.imma{
    max-width: 60%;
    margin-left: 8%;
}
.logs{
    max-width: 100%;
}
.home-head{
    text-align: center;
    padding: 10%;
}
.headings{
    text-align: center;
    font-family: 'Castoro Titling', cursive;
    margin-top: 8%;
    margin-bottom: 5%;
    font-weight: 700;

}
.pro{
    margin-top: 8%;
}
.our-serv{
    background-color: #ffffff;
}


.marginTop{
    margin-top: 8%;
}
.chooseUs{
  
}
.textxAlignCenter{
    text-align: center;
}
.smallheadings{
    color: #2a2a90;
    margin-left:8% ;
    font-weight: 700;
}
.known-para{
    padding: 7% 20% 0% 15%;
    
}
.known-img{
    max-width: 60%;
    margin-left: 1%;
}
.know-button{
    text-decoration: none;
    text-align: center;
    background-color:#2a2a90 ;
    color: white;
    padding: 17px 50px;
    margin-left:15%;
    border-radius: 0px;
}
.know-button:hover{

}
.hrm-img{
    max-width: 80%;
}
.know{
    background: url("../../img/office.png") no-repeat ;

}
.ja{
    visibility: visible;
}
.perSoftware{
    margin-top: 8%;
    padding: 2%;
}
.perSoftware-img{
    max-width:  100%;
}
.perPara{
    font-size: 1.08rem;
    margin-top: 2%;
    font-weight: 500;
    justify-content: left;
    padding: 5%;
        text-transform: capitalize;
      
}
.foo{
    margin-top: 9%;
    border-top: 1px solid grey;

}
.spec-para{
    padding:5%;
    font-size:1.4rem;
}

.home-entrepreneurs{
    text-align: center;
    background: rgba(0, 0, 0, 1); /* Black background color with transparency */
    backdrop-filter: blur(10px); /* Blur effect to create a frosted glass appearance */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid rgba(0, 0, 0, 0.849); /* Light border */
    border-radius: 10px; /* Optional: rounded corners */
    padding: 20px; /* Optional: padding for inner content */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: slight shadow for depth */
    margin: 20px; /* Optional: margin for spacing around the section */
    color: white; /* Ensures text is readable on the black background */  padding: 30px;
}
.entre-head{
    font-size: 3vh;
    margin-top:30px;
    color: white;
}
.entre-sub-head {
    margin-top: 40px;
    color: white;
}
.entre-para{
    margin-top: 30px;
    color: white;
    text-align: left;
    padding: 30px;
}
.entre-img {
    max-width: 60%;
    margin-top: 20px;
}
.new-one-b{
    padding: 20px 40px;
    text-decoration: none;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    transition-duration:0.2s;
}
.product-buttons:hover{
    background-color: white ;
    color: black;
}
.ar:hover{
    visibility: visible;
    color:black;
}

.right-inf{
padding: 10%;
text-align: center;
font-size: 1.5rem;
}



@media screen and (max-width: 720px){
    .known-img{
        max-width: 100%;
    }
    .known-para{
        padding: 0%;
    }
    .ja{
        display: none;
    }
    .hrm-img{
        max-width: 100%;
        margin-top: 10%;
    }
    .perPara{
        font-size: .8rem;
        margin-top: 1%;
        font-weight: 500;
        justify-content: left;
        padding: 1%;
        text-transform: capitalize;
    }
    .spec-para{
        padding:1%;
    }
    .smallheadings{
        color: #2a2a90;
        margin-left:8% ;
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 5%;
    }
    .home-para{
        text-align: left;
        justify-content: left;
        color:#ffffff;
        font-size: 2rem;
        max-width: 100%;
        margin-top: 20%;
    }

    .home-para-paragraph{
        text-align: left;
        justify-content: left;
        color: rgb(255, 255, 255);
        max-width: 100%;
        font-size: 1rem;
    }

    .home-entrepreneurs{
        text-align: left;
        background-color: #1d141c;
        padding: 30px;
    }

    .entre-head{
        font-size: 2.5vh;
        margin-top:10px;
        color: white;
    }
    .entre-sub-head {
        margin-top: 20px;
        color: white;
        font-size: 2vh;
        display: none;
    }
    .entre-para{
        margin-top: 20px;
        color: white;
        text-align: left;
        font-size: 1.6vh;
        padding: 0%;
    }
    .entre-img {
        max-width: 100%;
        margin-top: 30px;
    }
}
.bgx{
    background-image: url('../../img/addnexbanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    filter: brightness(79%);
    height:60vh;
}