.head{
    margin-top: 0%;
}
.services{
    margin-top: 20%;
}
.services h1{
    color: #2a2a90;
    font-size: 2.2rem;
    font-weight: 700;
    padding: 2%;
}
.services-main {
    text-align: left;
    padding:5%;
    font-size: 1.4rem;
}
.services-img{
    max-width: 100%;
}
@media screen and (max-width: 720px){
    .services{
        margin-top: 4%;
    }
    .services h1{
        color: #2a2a90;
        font-size: 2.2rem;
        font-weight: 700;
        padding: 3%;
    }
    .services-main {
        text-align: left;
        padding:1%;
        font-size: 1rem;
    }
}
