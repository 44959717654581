.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.register-form {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.register-form h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
}

.submit-button:hover {
  background-color: #0056b3;
}

.register-login-text {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.register-login-text a {
  color: #007bff;
  text-decoration: none;
}

.register-login-text a:hover {
  text-decoration: underline;
}

.message-box {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.message-box.success {
  background-color: #e0f7e0;
  color: #2e7d32;
  border: 2px solid #2e7d32;
}

.message-box.error {
  background-color: #fce4e4;
  color: #d32f2f;
  border: 2px solid #d32f2f;
}

.message-box .icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.message-box button {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.message-box.success button {
  background-color: #2e7d32;
  color: #fff;
}

.message-box.error button {
  background-color: #d32f2f;
  color: #fff;
}
