@import url('https://fonts.googleapis.com/css2?family=Playwrite+AR:wght@100..400&display=swap');

.entra-page-banner{
  width: 100%;
  filter: brightness(0.5);
}
.entra-header{
  text-align: center;
  font-weight: 900;
  margin-top: 5%;
  font-size: 5vh;
  background: -webkit-linear-gradient(to left, #C06C84, #6C5B7B, #355C7D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to left, #C06C84, #6C5B7B, #355C7D);
  background-clip: text;
  text-fill-color: transparent;
}

.entra-para-imp{
  font-size: 3vh;
  text-align: center;
  margin-top: 4%;
  font-family: "Playwrite AR", cursive;

}


@media screen and (max-width: 720px){

  .entra-header{
    text-align: left;
    color: #0d0d32;
    font-weight: 600;
    margin-top: 5%;
    font-size: 3.5vh;
  }
  
  .entra-para-imp{
    font-size: 2vh;
    text-align: left;
    margin-top: 4%;
    font-family: "Playwrite AR", cursive;
  
  }
}