.bannerImage{
max-width: 100%;
}
.main-text{
  position: relative;  
}
.on-image{
    position: absolute;
}
.h1{
    color: aliceblue;
}