@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.navbar{
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(255, 255, 255);
    font-size: 1.4rem;
    font-weight: 400;
}
.navbar-brand{
color:#0c0c3c;
outline: black;
font-size: 3rem;
letter-spacing: 1px;
font-family: "Quicksand", sans-serif;
font-optical-sizing: auto;
font-weight: 500;
font-style: normal;
letter-spacing: -0.5px;
}
.nav-item{
    margin-right:10px;
}
.nav-link:hover{
    color: #ababff;
} 
@media screen and (max-width: 720px){
    .navbar-brand{
        color:#0c0c3c;
        outline: black;
        font-size: 2rem;
        font-weight:400;
        letter-spacing: .1px;
        }

}