.tame-card {
    padding:3%;
    background-color: aliceblue;
}

.tame-card-image{
    max-width: 100%;
}

.team-paras{
    padding: 2% 2% 2% 0;
    text-align: left;
}
.namepara{
    margin-top: 4%;
    font-size: large;
    color: #2a2a90;
}