.about{
    margin-top: 5%;
}
.about-cont{
    margin-top: 5%;
    padding: 10%;
}
.about-head{
    color:#2a2a90;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
}
.about-para{
justify-content: center;
margin-top: 5%;
font-size: 1.5rem;
}
.about-img{
    max-width: 100%;
}
.new{
    background-color: #b4b4b440;
}
.a-head{
    text-align: center;
    font-size: 2.7rem;
    font-weight: 600;
    color:#2a2a90;
}
.a-para{
    margin-top: 5%;
    padding: 2% 10%;
    font-size: 2rem;
}
.time-img{
    max-width: 70%;
}
@media screen and (max-width: 720px){
    .about-para{
        justify-content: center;
        margin-top: 5%;
        font-size: 1rem;
    }
    .about-cont{
        margin-top: 0;
        padding: 2%;
    }
    .a-para{
        margin-top: 5%;
        padding: 2% 0%;
        font-size: 1rem;
    }
    .time-img{
        max-width: 100%;
    }
}