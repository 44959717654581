@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');


.blog-page-heading{
    font-size:9rem;
    color: #161d5a;
    text-align: center;
    font-family: "Protest Guerrilla", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    padding: 10%;
}

.blog-container{
    text-decoration: none;
}

.blog-mains{
padding: 2%;
border: 1px solid black;
margin-top: 1%;
}

.blog-title{
    font-size: 2.5rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    color: rgb(36, 39, 101);
}

.blog-author , .blog-date{
    font-size: 1.5rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    color: black;
}

.blog-para{
    font-size: 1.1rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    color: black;
}

.blog-main-image{
    max-width: 100%;
}

.sp-head{
    text-align: center;
    font-size: 5rem;
    font-family: "Caveat", cursive;
font-weight: 700;
margin-top: 3%;
}

.sp-author,.sp-date, .sp-estimated-time{
    font-family: "Caveat", cursive;
    font-size: 1.8rem;
    font-weight: 500; 
}

.sp-title{
    font-family: "Caveat", cursive;
    font-size: 4rem;
    font-weight: 500; 
    margin-top: 3%;
}

.spi-img{
    max-width: 100%;
}

.padds{
    font-size: 1.2rem;
    padding: 2%;
}

@media only screen and (max-width: 700px) {
    .blog-page-heading{
        font-size:5rem;
        color: #161d5a;
        text-align: center;
        font-family: "Protest Guerrilla", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        padding: 6%;
    }

    .blog-title{
        font-size: 2rem;
        font-family: "Quicksand", sans-serif;
        font-weight: 600;
        color: rgb(36, 39, 101);
    }

    .blog-author , .blog-date{
        font-size: 1rem;
        font-family: "Quicksand", sans-serif;
        font-weight: 400;
        color: black;
    }
    .blog-para{
        font-size: 0.7rem;
        font-family: "Quicksand", sans-serif;
        font-weight: 600;
        color: black;
    }
}